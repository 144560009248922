.tasksContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 16px;
    flex-wrap: wrap;
}

.tasksWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 16px;
    flex-wrap: wrap;
}

.ant-collapse .ant-collapse-content {
    background-color: var(--secondary-color);
}

.checkboxContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: start;
}

.taskContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    height: 100px;
    max-height: 100px;
    text-overflow: ellipsis;
    overflow-y: auto;
}

.taskNpcLabel {
    font-size: 18px;
    font-weight: 700;
    color: var(--secondary-text-color);
    opacity: 0.9;
}

.taskTaskLabel {
    font-size: 18px;
    font-weight: 400;
    color: var(--secondary-text-color);
    opacity: 0.9;
}
.taskExpLabel {
    font-size: 18px;
    font-weight: 400;
    color: var(--secondary-text-color);
    opacity: 0.9;
}
