.homeContainer {
    display: flex;
    flex-direction: column;
    padding: 32px;
    gap: 16px;
    color: var(--primary-text-color);
}

.levelContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
}
