.App {
    display: flex;
    height: 100vh;
    overflow-x: hidden; /* Para esconder a barra de rolagem horizontal */

    background-color: var(--primary-color);
    font-size: calc(10px + 2vmin);
}

.content {
    flex: 1;
    margin-left: 170px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: var(--primary-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

span {
    color: white;
    opacity: 0.8;
}

.levelContainer {
    display: flex;
    flex-direction: row;
}

:root {
    --primary-color: #1c1c1c;
    --secondary-color: #4f4f4f;
    --third-color: #ef7b45;
    --primary-text-color: #00abd1;
    --secondary-text-color: #def0fc;
    --third-text-color: #292c30;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
