.sidebar {
    position: fixed;
    left: -150px;
    top: 0;
    bottom: 0;
    width: 150px;
    background-color: #333;
    transition: all 0.3s ease;
}

.open {
    left: 0px !important;
}

.toggle-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
    z-index: 999;
}

.sidebar-menu {
    list-style-type: none;
    padding: 0;
    margin: 50px 0 0 0;
}

.sidebar-menu li {
    padding: 10px 0;
    text-align: center;
}

.sidebar-menu li a {
    text-decoration: none;
    color: white;
    font-size: 18px;
    transition: color 0.3s ease;
}

.sidebar-menu li a:hover {
    color: #61dafb;
}
